
import { defineComponent, ref, onMounted, watch } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiModels } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-growth-model-modal",
  props: {
    id: { type: Number, default: 0 },
    groupId: { type: Number, default: 0 },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addModelModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const modelGroupOptions = ref([]);

    const layoutOptions = ref([
      {
        label: t("common.checkbox"),
        value: "checkbox",
      },
      {
        label: t("common.switch"),
        value: "switch",
      },
      {
        label: t("common.radio"),
        value: "radio",
      },
      {
        label: t("common.radioButton"),
        value: "radio-button",
      },
      {
        label: t("common.dropdown"),
        value: "dropdown",
      },
      {
        label: t("common.dropdownMultiple"),
        value: "dropdown-multiple",
      },
      {
        label: t("common.textInput"),
        value: "text",
      },
      {
        label: t("common.textareaInput"),
        value: "textarea",
      },
      {
        label: t("common.rangeInput"),
        value: "range",
      },
      {
        label: t("common.rating"),
        value: "rate",
      },
      {
        label: t("common.cascader"),
        value: "cascader",
      },
      {
        label: t("common.cascaderMultiple"),
        value: "cascader-multiple",
      },
      {
        label: t("common.ajaxLiveSearch"),
        value: "live-search",
      },
      {
        label: t("common.ajaxLiveSearchMultiple"),
        value: "live-search-multiple",
      },
    ]);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: props.id,
      parent_id: 0,
      status: 10,
      name: "",
      short_key: "",
      layout: "",
      group_id: props.groupId,
      sort: 0,
      note: "",
      value_source_type: "",
      value_source: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      layout: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      value_source_type: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                !(
                  formData.value.layout == "text" ||
                  formData.value.layout == "range" ||
                  formData.value.layout == "switch"
                ) &&
                (value == "" || value == null)
              ) {
                reject(t("common.isRequired"));
              } else {
                reject();
              }
            });
          },
          trigger: "change",
        },
      ],
      value_source: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                !(
                  formData.value.layout == "text" ||
                  formData.value.layout == "range" ||
                  formData.value.layout == "switch"
                ) &&
                (value == "" || value == null)
              ) {
                reject(t("common.isRequired"));
              } else {
                reject();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const getFormData = (id) => {
      if (id) {
        loading.value = true;
        ApiModels.getModelInfo({ id: id })
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              formData.value = data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const getModelGroupOptions = () => {
      ApiModels.getModelGroupList({})
        .then(({ data }) => {
          if (data.code == 0) {
            modelGroupOptions.value = data.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.id) {
            editGrowthModel();
          } else {
            addGrowthModel();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addGrowthModel = () => {
      ApiModels.addModel(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addModelModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editGrowthModel = () => {
      ApiModels.updateModel(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addModelModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addModelModalRef.value);
      resetForm();
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      modalHideListener(addModelModalRef.value, () => {
        emit("reset-form");
        formData.value.id = 0;
      });
      getModelGroupOptions();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      addModelModalRef,
      submitButton,
      modelGroupOptions,
      layoutOptions,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
